import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ImageSection from "../../components/ui/sections/ImageSection";
import TwoColSection from "../../components/ui/sections/TwoColSection";
import Contact from "../../components/global/Contact";

const MarketingInternetowyPage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "working.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 640)
        }
      }
    }
  `);

  //   const pros = [
  //     {
  //       title: "Nowoczesne narzędzia",
  //       description:
  //         "Najważniejszy w marketingu jest pomysł. Jeżeli pomysł już mamy warto wiedzieć za pomocą jakich narzędzi go zrealizować.",
  //       icon: "ti-hummer",
  //     },
  //     {
  //       title: "Design",
  //       description:
  //         "Stawiamy na doskonałe odczucia przy obcowaniu z naszymi produktami. Wierzymy w prostotę, ciekawy design i użyteczność.",
  //       icon: "ti-layout",
  //     },
  //     {
  //       title: "Analityka",
  //       description:
  //         "Nie wróżymy z kapelszua. Wiemy, że żeby coś poprawić najpierw trzeba to zmierzyć.",
  //       icon: "ti-bar-chart",
  //     },
  //     {
  //       title: "Uporządkowane zarządzanie",
  //       description:
  //         "Z nami dokładnie wiesz co, kiedy i za ile. Stawiamy na porządek w projekcie, zwinne zarządzanie i procedury.",
  //       icon: "ti-calendar",
  //     },
  //   ];

  //   const process = [
  //     {
  //       title: "Plan działania",
  //       description:
  //         "Wspólnie myślimy o Twoich celach, dostosowujemy dostępne możliwości i spisujemy plan działania.",
  //     },
  //     {
  //       title: "Podpisanie e-umowy",
  //       description:
  //         "Lubimy ekologię, więc nie marnujemy papieru na umowy. Do rozpoczęcia współpracy wystarczy kliknięcie linku w mailu potwierdzające chęć zaakceptowania umowy.",
  //     },
  //     {
  //       title: "Realizacja planu",
  //       description:
  //         "W tym etapie po prostu musimy dowieźć to, na co się umawialiśmy. Jak już wspominaliśmy, mierzymy wszystko, więc efekty są czarno na białym.",
  //     },
  //   ];

  return (
    <Layout pageTitle="Marketing internetowy">
      <section className="container mx-auto px-5">
        <ImageSection
          subtitle="Growth hacking"
          title="Wyciśnijmy więcej z Twojego biznesu"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Technologia którą tworzymy jest doskonałym silnikiem, który
            potrzebuje doskonałego paliwa. Jest nim oczywiście marketing
            internetowy, który sprawia nam wiele radości. To dlatego, że lubimy
            starcie naszych produktów z użytkownikami, analizowanie feedbacku i
            reagowanie na niego. Wiemy, że na końcu każdej technologii stoi
            użytownik końcowy, który musi być zadowolony.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Pomożemy Ci w promocji używając zarówno płatnych jak i bezpłatnych
            kanałów. Wierzmy w moc lejków marketingowych i sukcesywnego
            wykonywania założonej strategii.
          </p>
        </ImageSection>
      </section>

      <TwoColSection title="Pomagamy w promocji naszych rozwiązań">
        <p>
          Jako autorzy technologii w naszych projektach mamy cały ekosystem
          narzędzi marketingowych z których możesz skorzystać. Dzięki temu
          rozwój projeków internetowych jest jeszcze prostszy i przyjemniejszy.
          Pozwól więc pomóc sobie w tym aby być dla klienta zawsze w odpowiednim
          miejscu i w odpowiednim czasie.
        </p>
      </TwoColSection>

      <TwoColSection title="Nasze mocne strony" background="gray">
        <h3 className="text-black text-2xl font-semibold mb-3">
          Korzystając z naszych rozwiązań zyskasz:
        </h3>
        <ul className="mb-3 list-disc list-inside">
          <li>profesjonalną obsługę</li>
          <li>jasny plan działania</li>
          <li>mierzalne efekty</li>
          <li>wielkie pokłady kreatywności</li>
          <li>fachowe doradztwo</li>
        </ul>
        <h3 className="text-black text-2xl font-semibold mb-3">
          Nasze kompetencje:
        </h3>
        <ul className="list-disc list-inside">
          <li>SEO</li>
          <li>analityka internetowa</li>
          <li>kampanie Google i Facebook</li>
          <li>prowadzenie profili w social-mediach</li>
          <li>marketing automation</li>
          <li>identyfikacja wizualna</li>
          <li>rozwój projektów internetowych</li>
        </ul>
      </TwoColSection>

      <Contact />
    </Layout>
  );
};

export default MarketingInternetowyPage;
